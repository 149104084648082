<template>
    <div class="page_booking">
        <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
            <div>
                <vs-button color="primary" type="border" icon-pack="feather" icon="icon-chevrons-left" class="dateBtn" v-on:click="addDay(-7)"></vs-button>
                <vs-button color="primary" type="border" icon-pack="feather" icon="icon-chevron-left" class="dateBtn" v-on:click="addDay(-1)"></vs-button>
            </div>
            <div class="text-center cursor click selector_date">
                <span v-on:click="toDay()">Aujourd'hui   </span><span class="sep"> - </span> <span v-on:click="week()">   7-J </span><br/>
                <cDate v-model="date" ref="cDate" />
            </div>
            <div>
                <vs-button color="primary" type="border" icon-pack="feather" icon="icon-chevron-right" class="dateBtn" v-on:click="addDay(1)"></vs-button>
                <vs-button color="primary" type="border" icon-pack="feather" icon="icon-chevrons-right" class="dateBtn" v-on:click="addDay(7)"></vs-button>
            </div>
            </vs-col>
        </vs-row>
        <br/>
        <div class="vx-row">
            <div class="vx-col w-full mb-base">
                
                    <BookingMatriciel :date="date" ref="booking">

                        <template v-slot:add="cel">
                            <b class="text-danger cursor" v-on:click="addResa(cel.add)">+</b>
                        </template>

                        <template v-slot:member="cel">
                            <span v-on:click="openInfoResa(cel.member.uid )" class="cursor">
                                <strong v-bind:style="getMemberColor(cel.member)">
                                    {{ getMember(cel.member.member) }}<br/>
                                </strong>
                                <span class="mr-4">{{ getMemberVest(cel.member.member) }}</span> <span v-html="getCredit(cel.member.member)"></span>
                                <b style="color:red;" v-if="getNbDayCreance(cel.member.member)"><br/><span>!</span></b>
                                <br/>
                            </span>
                        </template>

                        <template v-slot:lock="cel">
                            <span v-on:click="removeBooking(cel.lock.lock)" class="cursor">
                                Séance non-réservable
                            </span>
                        </template>

                        <template v-slot:closing="cel">
                            Fermeture
                        </template>

                    </BookingMatriciel>
               
            </div>
        </div>
        <MembrePopup ref="viewMember" v-on:refresh="relodBooking" v-on:copier="MbrCopier" v-on:move="BookMove" />
        <SelectAddPopup ref="viewSelectAdd" v-on:select="typeResaSelect" v-on:coller="MbrColler" v-on:clear="MbrClear"  v-on:move="BookMoveFinal" />
        <AddPopup ref="addBooking" v-on:refresh="relodBooking" />
        <AddMember ref="addMember" v-on:refresh="relodBooking" />
    </div>
</template>

<style lang="scss" scoped>

.header-sidebar 
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    h4 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        > button {
            margin-left: 10px;
        }
    }
}
.header-body{
    margin:20px;
}

.footer-sidebar
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    > button {
        border: 0px solid rgba(0, 0, 0, 0) !important;
        border-left: 1px solid rgba(0, 0, 0, 0.07) !important;
        border-radius: 0px !important;
    }
}
.btn_resa .vs-button{
    margin-bottom:10px;
}
.dateBtn{
    display:inline !important;
    margin:5px;
}
.vdp-datepicker input{
    text-align:center;
}

.sep{
    padding-left:10%; 
    padding-right:10%;
}




</style>

<script>
import BookingMatriciel from '@/components/booking/matriciel.vue'
//import BookingPanel from '@/components/booking/panel.vue'
import MembrePopup from '@/components/booking/membre.vue'
import SelectAddPopup from '@/components/booking/selectAdd.vue'
import AddPopup from '@/components/booking/add.vue'
import AddMember from '@/components/member/add.vue'
import cDate from '@/components/app/date'
import Credit from '@/assets/utils/credit'

import Utils from '@/assets/utils/utils';
import Fdate from '@/assets/utils/fdate';

import Member  from '@/database/models/member';

import autoRefresh from '@/assets/utils/autoRefresh'

export default {
	components:{
        BookingMatriciel,
        MembrePopup,
        SelectAddPopup,
        AddPopup,
        AddMember,
        cDate,
	},
    data(){
        return{
            date: new Date(),

            list_member:{},
            resaSelect:{},

            mbrColler:undefined,
            mbrMove:undefined,

            
            listm:[],

            
        }
	},
	mounted:function()
	{
        //autoRefresh
        autoRefresh.SetCallback(async()=>
        {
            await this.loadMember();

            this.$refs.booking.build()
        })
        autoRefresh.refresh();
    },
    watch:{
        $route (){
            autoRefresh.SetCallback(()=>
            {
                if(this.$route.params.date != undefined)
                    this.date = new Date( this.$route.params.date.replace(/-/g, "/") );
           
            })
            autoRefresh.refresh();
            // if(this.$route.params.date != undefined)
            //     this.date = new Date( this.$route.params.date.replace(/-/g, "/") );
            // autoRefresh.refresh();
        },
        date:function(){
            this.datechanged=true;
            let d = (this.date.getMonth()+1)+'-'+this.date.getDate()+'-'+this.date.getFullYear();
            if( d != this.$route.params.date )
                this.$router.push('/admin/booking/'+d);
        },

        
    },
    methods:{
        async relodBooking(){
            await this.refr()
            autoRefresh.refresh();
        },
        async refr(){
            for (var i in this.list_member) 
                {
                    let member = this.list_member[i];
                    let tab_credit = await Credit.getCreditFiltre( i, 'global' );
                    // member.print_global_credit = '<b>'+tab_credit.credit+'</b> / '+tab_credit.used;
                    member.print_global_credit = [tab_credit.credit,tab_credit.used];
                    
                    //créance
                    let creance = await Credit.calcCreance( i );

                    member.print_creance = 0;
                    
                    if((creance.dif-creance.recu) >0)
                    {
                        let t = (new Date()).getTime() - creance.date.getTime();
                        t = ((t/1000)/60/60/24)>>0;
                        if(t>31)
                            member.print_creance = t;
                    }
                }
        },
        async loadMember(){
            return new Promise(async(resolve)=>
            {
                //Date params
                if(this.$route.params.date)
                    this.date = new Date( this.$route.params.date.split('-').join('/') )

                //load memory
                this.list_member = await Member.AwaitGetAllMemory();
                for (var i in this.list_member) 
                {
                    let member = this.list_member[i];
                    let tab_credit = await Credit.getCreditFiltre( i, 'global' );
                    // member.print_global_credit = '<b>'+tab_credit.credit+'</b> / '+tab_credit.used;
                    member.print_global_credit = [tab_credit.credit,tab_credit.used];
                    
                    //créance
                    let creance = await Credit.calcCreance( i );

                    member.print_creance = 0;
                    
                    if((creance.dif-creance.recu) >0)
                    {
                        let t = (new Date()).getTime() - creance.date.getTime();
                        t = ((t/1000)/60/60/24)>>0;
                        if(t>31)
                            member.print_creance = t;
                    }
                }
                resolve();
            });
        },
        getMember( uid ){
            var mbr = this.list_member[uid]
            if(!mbr)
                return 'Membre archivé.'

            return Utils.StrCapital(mbr.first_name+" "+mbr.last_name)
        },
        getMemberVest( uid ){
            var mbr = this.list_member[uid]
            if(!mbr)
                return 'Membre archivé.'

            return mbr.vest
        },
        getCredit( uid )
        {
            
            var mbr = this.list_member[uid]
            this.clicked==false;
            if(!mbr)
                return ' ? / ?';

               
            return '<b>'+mbr.print_global_credit[0]+'</b> / '+ mbr.print_global_credit[1];
   
        },
        getNbDayCreance( uid )
        {
            var mbr = this.list_member[uid]
            if(!mbr)
                return 0;
            return mbr.print_creance;
        },
        getMemberColor( cel ){

            var mbr = this.list_member[ cel.member ]
            if(!mbr)
                return {}

            let type = 'm';
            switch(cel.type)
            {
                case 'e':
                    type = 'e';
                break;
                case 'c':
                    type = 'c';
                break;
            }

            return{
                'color': Member.color[ Member.getTypeSpec({
                    'type'  : type,
                    'active': mbr.active,
                }).toLowerCase() ]
            }
        },
        openInfoResa ( resa){
            this.$refs.viewMember.openPopup( resa )
        },
        addResa( cel ){
            this.resaSelect = cel
           
            this.$refs.viewSelectAdd.openPopup( this.mbrColler, this.mbrMove )
            
        },
        removeBooking(uid){
            //remove seance
            this.$refs.viewMember.removeBooking(uid)
        },
        typeResaSelect(type)
        {
            if(type=="e")
            {
                this.$refs.addMember.openPopup((uid_member)=>
                {
                    Member.getMemory(uid_member,(mbr)=>{
                        
                        this.$refs.addBooking.openPopup( this.date, this.resaSelect.machine, this.resaSelect.hour, type, mbr )
                        this.$refs.addBooking.add(1,0)
                    })
                })
                return
            }

            //console.log( this.resaSelect )
            this.$refs.addBooking.openPopup( this.date, this.resaSelect.machine, this.resaSelect.hour, type )

            //Résa type block
            if(type=='b')
                this.$refs.addBooking.add(0,0)
        },
        addDay( nb )
        {
            let d = new Date( this.date )
            d.setDate(d.getDate() + nb)
            
            let fd = ( d.getMonth()+1)+'-'+d.getDate()+'-'+d.getFullYear();
            if( fd != this.$route.params.date )
                this.$router.push('/admin/booking/'+fd).catch(()=>{})
        },
        toDay()
        {
            let d = new Date()
            
            let fd = ( d.getMonth()+1)+'-'+d.getDate()+'-'+d.getFullYear();
            if( fd != this.$route.params.date )
                this.$router.push('/admin/booking/'+fd).catch(()=>{})     
        },






        MbrCopier( mbr )
        {
            this.mbrColler = mbr
        },
        MbrColler()
        {
            this.$refs.addBooking.openPopup( this.date, this.resaSelect.machine, this.resaSelect.hour, this.mbrColler.type, this.mbrColler  )
            this.$refs.addBooking.add(1,0)
        },
        MbrClear()
        {
            this.mbrColler = undefined
            this.mbrMove = undefined
        },



        //deplacer
        BookMove( uid_booking )
        {
            this.mbrMove = uid_booking
        },
        BookMoveFinal()
        {
            //new dat
            let t = this.resaSelect.hour.split(':')
            let dd = new Date( this.date.toString() )
            dd.setHours( t[0] )
            dd.setMinutes( t[1] )
            dd.setSeconds( 0 )

            //console.log( Fdate.getISO_8601(dd) )

            //Move résa
            this.$refs.addBooking.move ( this.mbrMove, this.resaSelect.machine,  Fdate.getISO_8601(dd), undefined )
            this.mbrMove = undefined;
        },
        week(){
            let fd = ( this.date.getMonth()+1)+'-'+this.date.getDate()+'-'+this.date.getFullYear();
            this.$router.push('/admin/weekly/'+fd).catch(()=>{})
        }
    }
};

</script>